<template>
  <v-card tile class="">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>
              {{
                this.$vuetify.lang.current === "en"
                  ? "Add to payroll"
                  : "Agregar a nómina"
              }}
            </h2>
          </v-col>
          <v-col cols="12">
            <p class="">
              {{
                this.$vuetify.lang.current === "en"
                  ? "Find your workers in Ridery's app to add them in your payroll"
                  : "Encuentra a tus trabajadores dentro de la app de Ridery para agregarlos a tu nómina"
              }}
            </p>
          </v-col>
        </v-row>
        <ValidationObserver v-slot="{ invalid }">
          <v-form spellcheck="false">
            <v-row dense>
              <v-col cols="12" md="6">
                <ValidationProvider
                  v-if="searchType === 'Teléfono'"
                  name="Teléfono"
                  rules="required|min:10|max:10"
                  v-slot="{ errors }"
                >
                  <!-- <v-text-field
                    v-if="searchType === 'Teléfono'"
                    label="Teléfono"
                    :prepend-inner-icon="icons.mdiCellphone"
                    outlined
                    clearable
                    dense
                    v-model="phone"
                    :error-messages="errors[0]"
                  ></v-text-field> -->
                  <PhoneInput
                    v-if="searchType === 'Teléfono'"
                    :prepend-inner-icon="icons.mdiCellphone"
                    label="Teléfono"
                    v-model="phone"
                    dense
                    outlined
                    clearable
                    :long="true"
                    :error-messages="errors[0]"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-if="searchType === 'Correo electrónico'"
                  name="Correo electrónico"
                  rules="required|email"
                  v-slot="{ errors }"
                  :debounce="450"
                >
                  <v-text-field
                    v-if="searchType === 'Correo electrónico'"
                    label="Correo electrónico"
                    :prepend-inner-icon="icons.mdiEmailOutline"
                    outlined
                    dense
                    clearable
                    v-model="email"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  :items="['Teléfono', 'Correo electrónico']"
                  placeholder="Buscar por..."
                  outlined
                  dense
                  v-model="searchType"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  color="primary"
                  outlined
                  block
                  @click="handleSearch()"
                  :disabled="invalid"
                  :loading="loadingSearch"
                >
                  <v-icon>
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  <span class="ml-2"> Buscar </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
        <v-row dense class="mb-5 slide-in-bck-center" v-if="alertError">
          <v-col cols="12" class="color-red-text"
            ><v-icon style="color: #ef4370 !important">{{
              icons.mdiAlert
            }}</v-icon
            ><span class="ml-2"
              >El usuario no se encuentra registrado actualmente en la
              aplicación.</span
            ></v-col
          >
        </v-row>
        <v-row
          dense
          class="mb-5 slide-in-bck-center"
          v-if="alertErrorAlreadyBelongs"
        >
          <v-col cols="12"
            ><v-icon style="color: #ef4370 !important">{{
              icons.mdiAlert
            }}</v-icon
            ><span class="ml-2 color-red-text"
              >El usuario se encuentra registrado actualmente en otro panel
              corporativo.</span
            ><br /><span class="ml-2">Puede comunicarse a:</span>
            <br />
            <v-btn
              color="info"
              class="mt-2"
              href="mailto:corporativo@ridery.app"
              target="_blank"
            >
              <v-icon style="color: #ffffff !important">
                {{ icons.mdiEmailOutline }}
              </v-icon>
              <span class="ml-2">Correo electrónico</span>
            </v-btn>
            <v-btn
              color="success"
              class="mt-2 ml-2"
              href="https://api.whatsapp.com/send?phone=+584127156879&text=Quiero%20agregar%20un,%20usuario%20que%20pertenece%20a%20otro%20panel"
              target="_blank"
            >
              <v-icon style="color: #ffffff !important">
                {{ icons.mdiWhatsapp }}
              </v-icon>
              <span class="ml-2">Whatsapp</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="mb-5 slide-in-bck-center" v-if="notifyUserFound">
          <v-col cols="12" class="color-green-text"
            ><v-icon style="color: #3bd4ae !important">{{
              icons.mdiCheck
            }}</v-icon
            ><span class="ml-2">Usuario encontrado.</span></v-col
          >
        </v-row>
        <v-row dense :class="!$vuetify.breakpoint.mdAndUp && 'mt-8'">
          <v-col cols="12" md="6" class="d-flex flex-row justify-center">
            <span class="d-flex flex-row justify-center align-center">
              <!-- <v-text-field
                label="Nombre"
                outlined
                dense
                :disabled="true"
                v-model="user.first_name"
              /> -->
              <span class="d-flex flex-row justify-center align-center">
                <h4 class="font-weight-bold">Nombre:</h4>
                <h3 class="ml-2">
                  {{ user.first_name }}
                </h3>
              </span>
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-row justify-center">
            <span class="">
              <!-- <v-text-field
                label="Apellido"
                outlined
                dense
                :disabled="true"
                v-model="user.last_name"
              /> -->
              <span class="d-flex flex-row justify-center align-center">
                <h4 class="font-weight-bold">Apellido:</h4>
                <h3 class="ml-2">
                  {{ user.last_name }}
                </h3>
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row dense :class="!$vuetify.breakpoint.mdAndUp && 'mt-4'">
          <v-col cols="12" md="6" class="d-flex flex-row justify-center">
            <span class="">
              <!-- <v-text-field
                label="Correo electrónico"
                outlined
                dense
                :disabled="true"
                v-model="user.email"
              /> -->
              <span class="d-flex flex-row justify-center align-center">
                <h4 class="font-weight-bold">Correo electrónico:</h4>
                <h3 class="ml-2">
                  {{ user.email }}
                </h3>
              </span>
            </span>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-row justify-center">
            <span class="">
              <!-- <v-text-field
                label="Teléfono"
                outlined
                dense
                :disabled="true"
                v-model="user.phone"
              /> -->
              <span class="d-flex flex-row justify-center align-center">
                <h4 class="font-weight-bold">Teléfono:</h4>
                <h3 class="ml-2">
                  {{ user.phone }}
                </h3>
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row
          class="
            d-flex
            flex-md-row flex-column-reverse
            justify-center
            align-center
          "
        >
          <v-col cols="12" md="4">
            <v-btn color="primary" block outlined @click="handleClose()">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              color="primary"
              block
              :disabled="!allowAdd"
              @click="handleAdd()"
            >
              Aceptar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp, mdiCellphone, mdiEmailOutline, mdiCheck } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import PhoneInput from "@/components/PhoneInput";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";

export default {
  directives: { money: VMoney },
  components: { PhoneInput },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
        mdiCellphone,
        mdiEmailOutline,
        mdiCheck,
      },
      alertError: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      searchType: "Teléfono",
      phone: "",
      email: "",
      user: {},
      loadingSearch: false,
      overdraft: false,
      userFound: false,
      alertErrorAlreadyBelongs: false,
      notifyUserFound: false,
      allowAdd: false,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),
    async handleSearch() {
      let params = {};
      if (this.searchType === "Teléfono") {
        params["phone"] = this.phone;
      }
      if (this.searchType === "Correo electrónico") {
        params["email"] = this.email;
      }
      this.loadingSearch = true;
      try {
        const { data } = await axios.get("/find_user", { params: params });
        if (!!data.success) {
          let corporate_id = data.user.corporate_ids.find(
            (corp) => Boolean(corp.status) === true
          );
          if (!corporate_id) {
            this.user = data.user;
            this.userFound = true;
            this.allowAdd = true;
            this.loadingSearch = false;
            this.notifyUserFound = true;
            await new Promise((r) => setTimeout(r, 5000));
            this.notifyUserFound = false;
          } else {
            this.allowAdd = false;

            this.loadingSearch = false;
            this.alertErrorAlreadyBelongs = true;
            this.user = data.user;
            await new Promise((r) => setTimeout(r, 10000));
            this.alertErrorAlreadyBelongs = false;
          }
        } else {
          this.user.first_name = "";
          this.user.last_name = "";
          this.user.email = "";
          this.user.phone = "";
          this.allowAdd = false;
          this.loadingSearch = false;
          this.alertError = true;
          await new Promise((r) => setTimeout(r, 5000));
          this.userFound = true;
          this.alertError = false;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.loadingSearch = false;
    },
    async handleAdd() {
      this.setLoading(true);
      try {
        const { data } = await axios.post("/send_request", {
          limit:
            this.price.length >= 8
              ? parseFloat(this.price.replace(/,/g, ""))
              : parseFloat(this.price),
          is_allow_overdraft: this.overdraft ? 1 : 0,
          id: this.user._id,
        });
        if (!!data.success) {
          this.$dialog.notify.success(
            `${this.user.first_name} ${this.user.last_name} agregado, el usuario debe confirmar aprobación desde la app de Ridery de su teléfono`
          );
          this.me();
          this.$emit("submit", true);
        } else {
          throw data.error_code;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    handleClose() {
      this.$emit("submit", null);
    },
  },
  computed: {
    ...mapState("auth", ["wallet"]),
    computedDisabled() {
      if (this.userFound) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 200vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>
